import { Button, Card, CardContent, CardMedia } from "@mui/material"
import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

const Licence = ({ image, header, content, buttonLink }) => {
  return (
    <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
      <Card sx={{ maxWidth: 520 }} className="licenceCard">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <CardMedia component="img" height="400" image={image} alt="licence" />
        </ScrollAnimation>
        <CardContent className="licenceCard__content">
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={300}
          >
            <h3>{header}</h3>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={500}
          >
            {content ? <p>{content}</p> : ""}
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={500}
          >
            <Button
              component={Link}
              to={buttonLink}
              size="medium"
              variant="contained"
            >
              Book Now
            </Button>
          </ScrollAnimation>
        </CardContent>
      </Card>
    </ScrollAnimation>
  )
}

export default Licence
