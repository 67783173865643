import React from "react"
import { Grid } from "@mui/material"
import Offer from "./offer"
import ScrollAnimation from "react-animate-on-scroll"
import { getSrc } from "gatsby-plugin-image"

const Offers = ({ offersArray }) => {
  return (
    <Grid className="gridContainer" container spacing={5}>
      {offersArray.map((offer, index) => {
        return (
          <Grid key={index} className="offer__grid" item xs={12} sm={8} lg={4}>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              animateOnce={true}
              delay={100 + index * 100}
            >
              <Offer
                image={getSrc(offer.headerPost.thumbnail.localFile)}
                header={offer.headerPost.pageTitle}
                link={`/${offer.nodeType.toLowerCase()}s/${offer.slug}`}
              />
            </ScrollAnimation>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Offers
