import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import Next from "../images/svgs/next.svg"
import Pre from "../images/svgs/pre.svg"
import { Button, Container, Grid } from "@mui/material"
import ScrollAnimation from "react-animate-on-scroll"
import { graphql, Link, StaticQuery } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

const HeroSlider = (props) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return <Next className={className} style={{ ...style }} onClick={onClick} />
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return <Pre className={className} style={{ ...style }} onClick={onClick} />
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const [pageWidth, setPageWidth] = useState(769)
  useEffect(() => {
    setPageWidth(window?.innerWidth)
  }, [])
  return (
    <div className="heroSlider">
      <StaticQuery
        query={graphql`
          query HomeQuery {
            allWpPage(filter: { id: { eq: "cG9zdDozMw==" } }) {
              edges {
                node {
                  home {
                    sliderHome {
                      header1
                      header2
                      content
                      button {
                        text
                        link
                      }
                      image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 100)
                          }
                        }
                      }
                      imageMobile {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 100)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <Slider {...settings}>
            {data?.allWpPage?.edges[0]?.node?.home?.sliderHome.map(
              (slide, index) => {
                return (
                  <div key={index}>
                    <div className="layer"></div>
                    <div
                      className="slideBg"
                      style={
                        pageWidth > 768
                          ? {
                              backgroundImage: `url(${getSrc(
                                slide.image.localFile
                              )})`,
                              backgroundColor: " #01456a",
                            }
                          : {
                              backgroundImage: `url(${getSrc(
                                slide.imageMobile.localFile
                              )})`,
                              backgroundColor: " #01456a",
                            }
                      }
                    >
                      <Container>
                        <Grid container>
                          <Grid item md={6}>
                            <ScrollAnimation
                              animateIn="animate__fadeIn"
                              animateOnce={true}
                              delay={100}
                            >
                              <h1>{slide.header1}</h1>
                              <h1>{slide.header2}</h1>
                            </ScrollAnimation>

                            <ScrollAnimation
                              animateIn="animate__fadeIn"
                              animateOnce={true}
                              delay={300}
                            >
                              <p>{slide.content}</p>
                            </ScrollAnimation>

                            <ScrollAnimation
                              animateIn="animate__fadeIn"
                              animateOnce={true}
                              delay={500}
                            >
                              <Link to={slide.button.link}>
                                <Button variant="contained" size="large">
                                  {slide.button.text}
                                </Button>
                              </Link>
                            </ScrollAnimation>
                          </Grid>
                        </Grid>
                      </Container>
                    </div>
                  </div>
                )
              }
            )}
          </Slider>
        )}
      />
    </div>
  )
}

export default HeroSlider
