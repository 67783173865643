import { Button, Container, Grid } from "@mui/material"
import React from "react"
import ContactFormSection from "../components/contactFormSection"
import HeroSlider from "../components/heroSlider"
import InstagramSection from "../components/instagramSection"
import Layout from "../components/layout"
import Licences from "../components/licences"
import Offers from "../components/offers"
import Reviews from "../components/reviews"
import Seo from "../components/seo"
import Bg from "../images/svgs/BG.svg"
import ScrollAnimation from "react-animate-on-scroll"
import { graphql, Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {
  const node = data.allWpPage.edges[0].node
  const offersArray = node.home.offers
  const cto1 = node.home.cto1
  const coursesArray = node.home.courses || []
  const cto2 = node.home.cto2
  const reviewsArray = node.home.reviews
  const cto3 = node.home.cto3

  return (
    <Layout>
      <Seo title="Home Page" />
      <section className="hero">
        <HeroSlider />
      </section>
      <section className="offer">
        <Bg className="bgSvg1" />
        <Bg className="bgSvg2" />
        <Container>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <h2>What We Offer</h2>
            <Offers offersArray={offersArray} />
          </ScrollAnimation>
        </Container>
      </section>
      <section
        className="callToAction"
        style={{ backgroundImage: `url(${getSrc(cto1.image.localFile)})` }}
      >
        <Bg className="bgSvg" />
        <Container>
          <Grid container className="gridBox">
            <Grid item sm={2} lg={6}></Grid>
            <Grid item sm={10} lg={6}>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
                <div dangerouslySetInnerHTML={{ __html: cto1.content }} />
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeIn"
                animateOnce={true}
                delay={300}
              >
                <Button
                  component={Link}
                  to={cto1.button.link}
                  variant="contained"
                >
                  {cto1.button.text}
                </Button>
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="licence">
        <Container>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <h2>Our Courses</h2>
          </ScrollAnimation>
          <Licences coursesArray={coursesArray} />
        </Container>
      </section>

      <section className="mainCallToAction">
        <div
          className="backgroundImageDiv"
          style={{
            backgroundImage: `url(${cto2.image?.localFile?.childrenImageSharp[0]?.fixed?.src})`,
          }}
        ></div>

        <Container>
          <Grid container className="ctaBox">
            <Grid item sm={12} md={9}>
              <Grid container className="ctaBox__content">
                <Grid item sm={12} lg={8}>
                  <ScrollAnimation
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <h2>{cto2.headercto2 || ""}</h2>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                    delay={300}
                  >
                    <div dangerouslySetInnerHTML={{ __html: cto2.content }} />
                  </ScrollAnimation>
                </Grid>
                <Grid item sm={12} lg={4} className="ctoBtn">
                  <ScrollAnimation
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                    delay={300}
                  >
                    <Button
                      component={Link}
                      to={cto2.button.link || ""}
                      variant="contained"
                    >
                      {cto2.button.text || ""}
                    </Button>
                  </ScrollAnimation>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="reviews">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h2>Customer Reviews</h2>
        </ScrollAnimation>
        <Reviews reviewsArray={reviewsArray} />
      </section>

      <section className="partners">
        <div
          className="backgroundImageDiv"
          style={{
            backgroundImage: `url(${getSrc(cto3.image.localFile)})`,
          }}
        ></div>
        <div className="ctaLogo">
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={100}
          >
            <img src={getSrc(cto3.logo.localFile)} alt={cto3.image.altText} />
          </ScrollAnimation>
        </div>
        <Container className="ctoContent">
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <h2>{cto3.headercto3}</h2>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={300}
          >
            <div dangerouslySetInnerHTML={{ __html: cto3.content }} />
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={350}
          >
            <Button component={Link} to={cto3.button.link} variant="contained">
              {cto3.button.text}
            </Button>
          </ScrollAnimation>
        </Container>
      </section>

      <ContactFormSection />
      <InstagramSection />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDozMw==" } }) {
      edges {
        node {
          home {
            cto3 {
              headercto3
              content
              button {
                text
                link
              }
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            reviews {
              ... on WpReview {
                id
                Reviews {
                  name
                  content
                }
              }
            }
            cto2 {
              headercto2
              content
              image {
                altText
                localFile {
                  childrenImageSharp {
                    fixed {
                      src
                    }
                  }
                }
              }
              button {
                text
                link
              }
            }
            offers {
              ... on WpExperience {
                slug
                nodeType
                headerPost {
                  pageTitle
                  thumbnail {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
              ... on WpCourse {
                slug
                nodeType
                headerPost {
                  pageTitle
                  thumbnail {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
            }
            cto1 {
              content
              button {
                text
                link
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
            courses {
              ... on WpCourse {
                slug
                nodeType
                headerPost {
                  pageTitle
                  excerpt
                  thumbnail {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
