import React from "react"
import { Grid } from "@mui/material"
import Licence from "./licence"
import { getSrc } from "gatsby-plugin-image"

const Licences = ({ coursesArray }) => {
  return (
    <Grid container className="gridBox" spacing={4}>
      {coursesArray.map((course, index) => {
        return (
          <Grid item md={10} lg={4} className="gridItemLicence" key={index}>
            <Licence
              image={getSrc(course.headerPost.thumbnail.localFile)}
              header={course.headerPost.pageTitle}
              content={course.headerPost?.excerpt || ""}
              buttonLink={`/${course.nodeType.toLowerCase()}s/${course.slug}`}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Licences
